import Services from "../../../services";

class GradeApi {
  get() {
    return Services.get("/grade");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/grade/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  search(key) {
    return Services.get("/grade/page/?per_page=10&page=1&q=" + key);
  }

  getDropdown() {
    return Services.get("/grade/dropdown");
  }

  show(value) {
    return Services.put("/grade/show", value);
  }

  hide(value) {
    return Services.put("/grade/hide", value);
  }

  create(value) {
    return Services.post("/grade", value);
  }

  update(value) {
    return Services.put("/grade", value);
  }

  delete(value) {
    return Services.post("/grade/delete", value);
  }

  checkDuplicateNama(params) {
    return Services.get('/grade/check_duplicate/', { params })
  }
}

export default new GradeApi();
