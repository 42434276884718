import Services from "../../../services";

class PangkatApi {
  get() {
    return Services.get("/pangkat");
  }

  getPage(page, dataLength, key) {
    return Services.get(`/pangkat/page/?per_page=${dataLength}&page=${page}&q=${key}`);
  }

  getDropdown() {
    return Services.get("/pangkat/dropdown");
  }

  search(key) {
    return Services.get("/pangkat/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("/pangkat/show", value);
  }

  hide(value) {
    return Services.put("/pangkat/hide", value);
  }

  create(value) {
    return Services.post("/pangkat", value);
  }

  update(value) {
    return Services.put("/pangkat", value);
  }

  delete(value) {
    return Services.post("/pangkat/delete", value);
  }

  checkDuplicateNama(params) {
    return Services.get('/pangkat/check_duplicate/', { params })
  }
}

export default new PangkatApi();
