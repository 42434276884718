import Services from "../../../services";

class HirarkiApi {
  get() {
    return Services.get("/hirarki_unit_organisasi");
  }

  getDropdown() {
    return Services.get("/hirarki_unit_organisasi/dropdown");
  }

  getPage(page, dataLength, key) {
    return Services.get(
      `/hirarki_unit_organisasi/page/?per_page=${dataLength}&page=${page}&q=${key}`
    );
  }

  search(key) {
    return Services.get("/hirarki_unit_organisasi/page/?per_page=10&page=1&q=" + key);
  }

  show(value) {
    return Services.put("/hirarki_unit_organisasi/show", value);
  }

  hide(value) {
    return Services.put("/hirarki_unit_organisasi/hide", value);
  }

  create(value) {
    return Services.post("/hirarki_unit_organisasi", value);
  }

  update(value) {
    return Services.put("/hirarki_unit_organisasi", value);
  }

  delete(value) {
    return Services.post("/hirarki_unit_organisasi/delete", value);
  }

  checkDuplicateNama(params) {
    return Services.get('/hirarki_unit_organisasi/check_duplicate/', { params })
  }

}

export default new HirarkiApi();
